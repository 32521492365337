// const DEBUG = true;
const DEBUG = false;

let localhost = "";
let apiURL = "";
let ip = "";
let ws = "";
if (DEBUG) {
  ip = "127.0.0.1:8000";
  localhost = `http://${ip}`;
} else {
  ip = "belakangucapan.pesansiar.com"; //Apjii
  localhost = `https://${ip}`;
}
export const baseurl = `${localhost}${apiURL}`;
export const API_URL_getsettings = `${baseurl}/get-settings/`;
export const API_URL_createsettings = `${baseurl}/create-settings/`;
export const API_URL_kontaks = `${baseurl}/kontaks/`;
export const API_URL_createkontak = `${baseurl}/create-kontak/`;
export const API_URL_importkontak = `${baseurl}/import-kontak/`;
export const API_URL_deleteallkontak = `${baseurl}/delete-all-kontak/`;
export const API_URL_edelkontak = `${baseurl}/edel-kontak/`;
export const API_URL_broadcast = `${baseurl}/broadcast/`;
